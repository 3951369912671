<template>
  <v-container>
    <h1>Social</h1>
    <v-row>
      <v-col md="6" cols="12">
        <v-card>
          <v-card-title>
            Following
            <v-spacer></v-spacer>
            <v-btn v-on:click="addFriendDialog = true" color="success">Add Follow</v-btn>
          </v-card-title>
          <v-data-table :items="friends" :headers="headers" :sort-by="['username']" :items-per-page="15">
            <template #item.actions="{ item }">
              <div class="d-flex">
                <v-btn color="success" v-on:click="openUserFlightsMenu(item.username)" style="margin-right: 5px">View</v-btn>
                <v-btn color="error" v-on:click="removeFriend(item.username)" style="margin-right: 5px">Delete</v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <AddFriendModal :dialog-state.sync="addFriendDialog" :friends.sync="friends"/>
    <UserFlightsDialog :dialog-state.sync="viewFriendsDialog" :friend-username="viewFriendUsername"/>
  </v-container>
</template>

<script>
import AddFriendModal from "../components/AddFriendModal";
import {mapGetters} from "vuex";
import UserFlightsDialog from "../components/UserFlightsDialog";
export default {
  name: "Social",
  components: {UserFlightsDialog, AddFriendModal},
  data: function (){
    return {
      headers: [
        {
          text: 'Name',
          value: 'username'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          width: '1%'
        },
      ],
      addFriendDialog: false,
      viewFriendsDialog: false,
      viewFriendUsername: null,
      friends: [],
    }
  },
  created: function (){
    this.getFriends()
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    getFriends: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/getFriends", requestOptions)
        .then(response => response.json())
        .then(function (data){
          this.friends = data['friends']
        }.bind(this))
    },
    removeFriend: function (friendUsername){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        }
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/removeFriend?username="+friendUsername, requestOptions)
        .then(response => {
          if (response.ok){
            console.log("Deleted!")
            this.friends = this.friends.filter(d => d['username'] !== friendUsername)
          }else{
            console.error("an error occurred")
          }
        })
    },
    openUserFlightsMenu: function(username){
      this.viewFriendUsername = username
      this.viewFriendsDialog = true
    }
  }
}
</script>

<style scoped>
.actionButtons .v-btn{
  margin:5px;
}
</style>
