<template>
  <v-dialog v-model="internalDialogState" style="z-index: 20" width="500">
    <v-card v-on:keyup.enter="addFriend(friendName)">
      <v-card-title class="headline">
        Airframe
      </v-card-title>
      <v-card-text>
        <v-text-field :label="'Friend'" v-model="friendName" :error-messages="errorString"/>
        <div class="actionsContainer">
          <v-btn color="success" v-on:click="addFriend(friendName)">Add and close</v-btn>
          <v-btn color="error" v-on:click="internalDialogState = false">Close</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AddFriendModal",
  props: ['dialogState', 'friends'],
  data: function (){
    return {
      friendName: "",
      errorString: "",
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
  },
  methods: {
    addFriend: function (friendUsername){
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        }
      }
      fetch(this.$baseURL+"/"+this.currentUser['user']+"/addFriend?username="+friendUsername, requestOptions)
        .then(response => {
          response.json().then(data => {
            if (response.ok) {
              if (data != null) {
                let friendsTemp = this.friends
                friendsTemp.push(data)
                this.$emit('update:friends', friendsTemp)
                this.internalDialogState = false
              }
            }else{
              this.errorString = data
              console.error("an error occurred")
            }
          })
        })
    }
  },
  watch: {
    internalDialogState: function (val){
      if (!val){
        this.friendName = null
      }
    },
    friendName: function(){
      this.errorString = ""
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.actionsContainer .v-btn{
  margin: 5px;
}
</style>
