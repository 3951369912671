<template>
  <v-dialog v-model="internalDialogState" width="1200">
    <v-card>
      <v-card-title class="headline">
        User Flights
      </v-card-title>
      <v-card-text>
        <v-data-table :items="userFlightsOrdered" :headers="headers">
          <template #item.start_time="{ item }">
            <p style="margin-bottom: 0">{{formatDate(item['start_time'])}}</p>
          </template>
          <template #item.in_flight_length="{ item }">
            <p style="margin-bottom: 0">{{msToTime(item['in_flight_length'])}}</p>
          </template>
          <template #item.actions="{ item }">
            <div class="d-flex" style="justify-content: flex-end">
              <v-btn color="success" v-on:click="openSharedFlight(item.id)" style="margin-right: 5px">View</v-btn>
            </div>
          </template>
        </v-data-table>
        <div class="actionsContainer">
          <v-btn color="error" v-on:click="internalDialogState = false">Close</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import Shared from "./Shared";

export default {
  name: "UserFlightsDialog",
  props: ['dialogState', 'friendUsername'],
  data: function (){
    return {
      headers: [
        {
          text: 'Flight Number',
          value: 'flight_number'
        },
        {
          text: 'Origin',
          value: 'origin',
        },
        {
          text: 'Start Flight',
          value: 'start_time'
        },
        {
          text: 'Destination',
          value: 'destination'
        },
        {
          text: 'In Flight Length',
          value: 'in_flight_length'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right'
        }
      ],
      userFlights: []
    }
  },
  created: function(){

  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    userFlightsOrdered: function (){
      return this.userFlights.sort(function (a, b) {
        return new Date(b['start_time']) - new Date(a['start_time'])
      })
    }
  },
  methods: {
    getFlights: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }
      fetch(this.$baseURL+"/"+this.friendUsername+"/getFlights", requestOptions)
        .then(response => response.json())
        .then(function (data){
          this.userFlights = data['flights']
          console.log(this.userFlights)
        }.bind(this))
    },
    openSharedFlight: function (id){
      let page = this.$router.resolve({name: "FlightShare", params: {flightID: id}})
      window.open(page.href, "_blank")
    },
    formatDate: function(input){
      let inDate = new Date(input)
      // Go has weird date before 1970 meaning null date is negative
      if (inDate.getTime() < 0){
        return "UNKNOWN"
      }
      return inDate.toLocaleString()
    },
    msToTime: function(duration) {
      return Shared.methods.msToTime(duration)
    },
  },
  watch: {
    internalDialogState: function(val){
      if (val){
        this.getFlights()
      }else{
        this.userFlights = []
      }
    }
  }
}
</script>

<style scoped>
.actionsContainer{
  display: flex;
  justify-content: flex-end;
}

.actionsContainer .v-btn{
  margin: 5px;
}
</style>
